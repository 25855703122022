//
// ion Range Slider Component
//


.ion-range-slider {
  .irs {
    .irs-min,
    .irs-max,
    .irs-from,
    .irs-to,
    .irs-single {
      padding: 2px 5px 1px 5px;
    }
  }
}
