//
// BlockUI
//


// Base
.blockui {
  background: $white;
  box-shadow: $dropdown-box-shadow;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;

  &.blockui-noshadow {
    box-shadow: none;
  }

  @include border-radius($border-radius);

  > span {
    color: $dark-75;
    padding: 0.75rem 1.2rem;
    font-size: 1rem;
    font-weight: 400;
    flex-wrap: nowrap;

    > .loader,
    > .spinner {
      margin-right: 1.25rem;
    }
  }
}
