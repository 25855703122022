//
// jQVMap
//


.jqvmap {
  position: relative;
  overflow: hidden;

  .jqvmap-zoomout,
  .jqvmap-zoomin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    background-color: $gray-200;
    color: $dark-75;
    font-size: 1rem;
  }

  .jqvmap-zoomout {
    top: 40px;
  }
}
