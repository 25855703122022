//
// DualListBox
//

// Base
.dual-listbox {
  // Search input
  .dual-listbox__search {
    width: 225px;
    border: 1px solid $gray-200;
    color: $input-plaintext-color;
    outline: none !important;
    @include border-radius($border-radius);

    &:active,
    &:focus {
      border-color: $input-focus-border-color !important;
    }

    &.dual-listbox__search--hidden {
      display: none;
    }
  }

  // Container
  .dual-listbox__container {
    display: flex;
    flex-wrap: nowrap;

    > div:not(.dual-listbox__buttons) {
      width: auto;
      flex-grow: 1;
      flex-basis: 0;
    }

    .dual-listbox__title {
      color: $dark-75;
      font-weight: 500;
      font-size: 1.1rem;
      padding: 0.75rem 0.75rem;
      border: 1px solid $gray-200;
      border-bottom: 0;
      @include border-top-left-radius($border-radius);
      @include border-top-right-radius($border-radius);
    }

    .dual-listbox__buttons {
      margin: 0 1.5rem;

      .dual-listbox__button {
        @include button-reset();
        @include border-radius($border-radius);
        margin-bottom: 0.5rem !important;
        padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
        color: $dark-50;
        font-weight: 500;
        font-size: 0.9rem;
        background-color: $gray-100;
        transition: all 0.3s ease;

        i {
          transition: $transition;
          font-size: 0.6rem;
          color: $text-muted;
        }

        &:hover {
          transition: $transition;
          color: $primary;

          i {
            transition: $transition;
            color: $primary;
          }
        }
      }
    }

    .dual-listbox__selected,
    .dual-listbox__available {
      width: auto;
      color: $input-plaintext-color;
      border: 1px solid $gray-200;
      @include border-bottom-left-radius($border-radius);
      @include border-bottom-right-radius($border-radius);

      .dual-listbox__item {
        padding: 0.75rem 0.75rem;
        border-bottom: 1px solid $gray-200;

        &:last-child {
          border-bottom: 0;
        }

        &.dual-listbox__item--selected {
          background-color: $gray-100;
        }
      }
    }
  }
}
