.datepicker {
  $cell-size: 35px;
  $container-spacing: 10px;

  padding: $container-spacing;

  table {
    width: 100%;

    thead {
      th {
        display: table-cell;

        &.picker-switch {
          color: $dark-75;
          font-weight: 500;
          display: table-cell;
          font-size: 1rem;

          &:hover {
            color: $primary;
            background: $gray-100 !important;
          }
        }

        &.prev,
        &.next {
          span {
            font-size: 0.8rem;
            color: $dark-50;
          }

          &:hover {
            cursor: pointer;

            span {
              color: $primary;
            }
          }
        }

        &.disabled {
          color: $text-muted;
          cursor: not-allowed;
        }
      }
    }
  }

  .datepicker-switch {
    color: $dark-75;
    font-weight: 500;
    display: table-cell;
    font-size: 1rem;

    &:hover {
      color: $primary;
      background: $gray-100 !important;
      cursor: pointer;
    }
  }

  .datepicker-days {
    table {
      tr {
        td,
        th {
          font-size: 1rem;
          width: $cell-size;
          height: $cell-size;
          padding: 0;
          font-weight: regular;
          vertical-align: middle;
          text-align: center;
          @include border-radius($border-radius);
        }
      }

      tbody {
        tr > td {
          color: $dark-50;

          &:hover {
            background: $gray-100;
            cursor: pointer;
          }

          &.old {
            color: $dark-50;
          }

          &.new {
            color: $dark-75;
          }

          &:focus,
          &.active {
            background: $primary !important;
            color: $white !important;
          }

          &.selected {
            background: $primary-light;
            color: $primary;
          }

          &.disabled {
            color: $text-muted;
            cursor: not-allowed;
          }

          &.today {
            position: relative;
            background: $primary-light !important;
            color: $primary !important;

            &:before {
              content: '';
              display: inline-block;
              border: solid transparent;
              border-width: 0 0 7px 7px;
              border-bottom-color: $primary;
              border-top-color: $primary;
              position: absolute;
              bottom: 4px;
              right: 4px;
            }
          }
        }
      }
    }
  }

  .datepicker-months,
  .datepicker-years,
  .datepicker-decades {
    table {
      tr {
        td,
        th {
          font-size: 1rem;
          width: $cell-size;
          height: $cell-size;
          padding: 0;
          font-weight: regular;
          vertical-align: middle;
          text-align: center;
          @include border-radius($border-radius);

          span {
            color: $dark-50;

            &:hover {
              background: $gray-100;
            }

            &.old {
              color: $dark-50;
            }

            &.new {
              color: $dark-75;
            }

            &:focus,
            &.active {
              background: $primary !important;
              color: $white !important;
            }

            &.selected {
              background: $primary-light;
              color: $primary;
            }

            &.today {
              position: relative;
              background: $primary-light !important;
              color: $primary !important;

              &:before {
                content: '';
                display: inline-block;
                border: solid transparent;
                border-width: 0 0 7px 7px;
                border-bottom-color: $primary !important;
                border-top-color: $primary !important;
                position: absolute;
                bottom: 4px;
                right: 4px;
              }
            }

            &.disabled {
              color: $text-muted;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}
