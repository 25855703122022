//
//  Bootstrap Timepicker
//


// Base
.bootstrap-timepicker-widget {
  left: auto;
  width: 180px;
  @include border-radius($border-radius);

  .bootstrap-timepicker-hour,
  .bootstrap-timepicker-minute,
  .bootstrap-timepicker-meridian,
  .bootstrap-timepicker-second {
    @include input-reset();
    color: $dark-50;
    font-weight: 500;
  }

  table td {
    @include border-radius($border-radius);
    text-align: center;

    > a {
      border: 0;
      @include border-radius($border-radius);
      width: 33px;
      height: 33px;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      span {
        font-size: 0.75rem;
        color: $text-muted;
        line-height: 1;
      }

      &:hover {
        background: $gray-100;
      }
    }
  }
}
