//
// Bootstrap Notify
//


.alert[data-notify] {
  min-width: 300px;
  padding: 1rem 1.5rem;

  .close {
    right: 1rem !important;
    padding: 0.25rem 0 0 2rem;;
    font-weight: 300;

    &:before {
      font-size: 0.75rem;
      color: #fff;
      opacity: 0.7;
      transition: $transition;
      @include ki(get($ki-types, close));
    }

    &:hover {
      &:before {
        opacity: 1;
        transition: $transition;
      }
    }
  }

  // Tablet mode
  @include media-breakpoint-down(md) {
    max-width: 70%;
  }

  // Mobile mode
  @include media-breakpoint-down(md) {
    max-width: 90%;
  }

  &[data-notify-position=top-center],
  &[data-notify-position=bottom-center] {
    width: 30%;

    // Tablet mode
    @include media-breakpoint-down(md) {
      width: 70%;
    }

    // Mobile mode
    @include media-breakpoint-down(md) {
      width: 90%;
    }
  }

  .icon {
    position: absolute;
  }

  [data-notify=title] {
    display: block;
    font-weight: 500;
  }

  .icon ~ [data-notify=title] {
    padding-left: 2.85rem;
  }

  .icon ~ [data-notify=message] {
    display: inline-block;
    padding-left: 2.85rem;
  }

  [data-notify=title]:not(:empty) ~ [data-notify=message] {
    margin-top: 0.2rem;
  }

  .progress {
    margin-top: 0.5rem;
    line-height: 0.5rem;
    height: 0.5rem;
  }
}
